<template>
  <div>
    <tabla titulo="Clientes" 
          :headers = "headers"
          :arreglo = "ClientesGetter.items"
            />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import tabla from '@/components/clientes/index';

export default {
  components: {
    tabla
  },
  
  created(){
    this.ConsultarClientes()
  },

  data: () =>{
    return{
    headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'NOMBRE', value: 'nombre' },
        { text: 'CELULAR', value: 'celular' },
        { text: 'FECHA REGISTRO', value: 'fecha_registro' },
        { text: 'PV', value: 'punto_venta'},
        { text: 'ACTIVO', value: 'active' },
        { text: 'ACCIONES', value: 'actions', sortable: false },
      ],

    }
  },

  

  methods: {
    ...mapActions(['ConsultarClientes']),

  },

  computed:{
      ...mapGetters(['ClientesGetter'])
  }
}
</script>
