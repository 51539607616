<template>
    <div>
      <v-form
         v-on:submit.prevent="DialogAceptar = true"
          ref="form"
          lazy-validation
      >
        <v-card>
        <v-card-title>
          <span class="headline">{{Accion}}</span>
          <v-spacer></v-spacer>
          <span v-if="Item.id != 0" class="headline">ID: {{Item.id}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
                       <v-row>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.nombre"
                  label="Nombre *"
                  hint="Nombre completo del usuario del sistema"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

             <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.celular"
                  label="Celular *"
                  hint="Número de celular del usuario del sistema"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  v-model="Item.activo"
                  :items="SINO"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="¿Usuario está activo?"
                  hint="Indique si el usuario se encuentra activo en el sistema"
                  persistent-hint
                  :rules="[v => v >= 0 || 'Item Requerido']"
                  required
                ></v-select>
              </v-col>

            </v-row>
          </v-container>

          <small>* indica campo requerido</small>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="submit"
          >
            Guardar
          </v-btn>
          <v-btn
            color="error"
            @click="Ocultar()"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>


     <v-dialog
      v-model="DialogAceptar"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          {{Accion}}
        </v-card-title>
        <v-card-text>¿Estas seguro de guardar?.</v-card-text>

        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
             @click="DialogAceptar = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="Guardar()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </div>
</template>

<script>
import { mapState, mapGetters, mapActions} from 'vuex'

export default ({

  props:['Accion','Item','NuevoActivo'],

  watch:{
      Alerta(value){
        if(value.error == false){
          this.Ocultar()
        }
      }
  },

  data: () => ({
    var: null,
    DialogAceptar: false,
    ItemEnviar: {id: null, nombre: null, celular: null, activo: null}
  }),

  methods: {
     ...mapActions(['NuevoCliente', 'ConsultarClientes']),

    Guardar (){
      this.ItemEnviar.id = this.Item.id
      this.ItemEnviar.nombre = this.Item.nombre
      this.ItemEnviar.celular = this.Item.celular
      this.ItemEnviar.activo = this.Item.activo

      if(this.Accion == 'Nuevo'){
        this.ItemEnviar.punto_venta = this.DatosdeUsuario.pv
      }else{
        this.ItemEnviar.punto_venta = this.Item.punto_venta
      }

      this.NuevoCliente(this.ItemEnviar)
      setTimeout( () => {this.ConsultarClientes()}, 500)
      this.DialogAceptar = false
    },

    Ocultar(){
        this.$emit('NuevoActivo', false)
    }
  },

  computed:{
    ...mapState(['Alerta']),
    ...mapGetters(['SINO','DatosdeUsuario'])
  }


})
</script>
